import {
  isRouteErrorResponse,
  useParams,
  useRouteError,
} from '@remix-run/react'
import type { ErrorResponse } from '@remix-run/router'
import { captureRemixErrorBoundaryError } from '@sentry/remix'

import Logo from '~/elements/logo'

type StatusHandler = (info: {
  error: ErrorResponse
  params: Record<string, string | undefined>
}) => JSX.Element | null

export function getErrorMessage(error: unknown) {
  if (typeof error === 'string') return error
  if (
    error &&
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return error.message
  }

  // eslint-disable-next-line no-console
  console.error('Unable to get error message for error', error)
  return 'Unknown Error'
}

export function GeneralErrorBoundary({
  defaultStatusHandler = ({ error }) => (
    <>
      <h3>Error {error.status}</h3>
      <p>{error.data}</p>
    </>
  ),
  statusHandlers,
  unexpectedErrorHandler = unexpectedErrorDefaultHandler,
  showLinkLogo = false,
}: {
  defaultStatusHandler?: StatusHandler
  statusHandlers?: Record<number, StatusHandler>
  unexpectedErrorHandler?: ({ error }: { error: unknown }) => JSX.Element | null
  showLinkLogo?: boolean
}) {
  const error = useRouteError()
  const params = useParams()

  captureRemixErrorBoundaryError(error)

  if (typeof document !== 'undefined') {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  return (
    <>
      {showLinkLogo ? (
        <div>
          <a href="/">
            <Logo />
          </a>
        </div>
      ) : null}

      {isRouteErrorResponse(error)
        ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
            error,
            params,
          })
        : unexpectedErrorHandler({ error })}
    </>
  )
}

export const unexpectedErrorDefaultHandler = ({
  error,
}: {
  error: unknown
}) => {
  return <p>Error: {getErrorMessage(error)}</p>
}
